.container {
  width: 12px;
  height: 12px;
}

.line {
  position: relative;
  border-radius: 12px;
  margin: 2px 0 2px 0;
  width: 12px;
  height: 1px;
  background-color: rgb(77, 82, 93)
}

.equalizing div {
  background-color: #F4D400;
}

.equalize1 {
  animation: equalize 0.5s infinite alternate
}

.equalize2 {
  animation: equalize 0.4s infinite alternate
}

.equalize3 {
  animation: equalize 0.7s infinite alternate
}

@keyframes equalize {
  0% {
    width: 3px
  }
  100% {
    width: 12px
  }
}


