@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';
@import '../../styles/variables';

.cardLayout {
  background: $station-card-radio-palette-background;
  border-radius: 24px;
}

.card {
  border: double 1px transparent;
  background-image: none, linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 33.33%, rgba(0, 0, 0, 0.1) 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 156px;
  min-height: 144px;
  border-radius: 24px;
  backdrop-filter: blur(22px);
  overflow: hidden;
}

.cardBackground {
  display: flex;
  border-radius: 23px;
  background: $station-card-radio-palette-background;
}

.index {
  color: #EFF1F3;
  font-size: 10px;
  line-height: 16px;
  margin-bottom: 8px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: 'ss01' on, 'ss08' on;
  color: #EFF1F3;
  white-space: normal;
  padding-bottom: 10px;
}

.song {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss08' on;
  color: #EFF1F3;
  padding-bottom: 4px;
}

.artist {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: 'ss01' on, 'ss08' on;
  color: #7A8592;
  padding-bottom: 16px;
}


