@import '~@kyivstarteam/ks-react-design-system-public/Styles/bosons';

.vinyl {
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  backdrop-filter: blur(22px);
  border-radius: 999px;
  overflow: hidden;
}

.withBorder {
  display: flex;
  border-radius: 999px;
  border: 1px solid rgba(45, 46, 48, .5);
}

.vinylCenter {
  position: absolute;
  left: 36%;
  right: 36%;
  top: 36%;
  bottom: 36%;
  border: 1px solid rgba(45, 46, 48, .5);
  backdrop-filter: blur(22px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 999px;
  background: #33312d;
}

.vinylCenter2 {
  position: absolute;
  left: 42%;
  right: 42%;
  top: 42%;
  bottom: 42%;
  border: 1px solid rgba(45, 46, 48, .5);
  backdrop-filter: blur(22px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 999px;
  background: #141416;
}

.image {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 56px;
  height: 56px;
  margin: -28px 0 0 -28px;
  border-radius: 999px;
}

.rotate {
  -webkit-animation:spin 20s linear infinite;
  -moz-animation:spin 20s linear infinite;
  animation: spin 20s linear infinite;
}

.animationRunning {
  animation-play-state: running;
}
.animationPaused {
  animation-play-state: paused;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
